const tokenPath = 'authToken'

const windowGlobal = typeof window !== 'undefined' && window

export const getStoredAuthToken = () => windowGlobal?.localStorage?.getItem(tokenPath)

export const storeAuthToken = (token: string) => windowGlobal?.localStorage?.setItem(tokenPath, token)

export const removeStoredAuthToken = () => windowGlobal?.localStorage?.removeItem(tokenPath)

const tokenPathRevisor = 'authTokenRevisor'

export const getStoredAuthTokenRevisor = () => windowGlobal?.localStorage?.getItem(tokenPathRevisor)

export const storeAuthTokenRevisor = (token: string) =>
    windowGlobal?.localStorage?.setItem(tokenPathRevisor, token)

export const removeStoredAuthTokenRevisor = () => windowGlobal?.localStorage?.removeItem(tokenPathRevisor)

const tokenPathAdmin = 'authTokenAdmin'

export const getStoredAuthTokenAdmin = () => windowGlobal?.localStorage?.getItem(tokenPathAdmin)

export const storeAuthTokenAdmin = (token: string) => windowGlobal?.localStorage?.setItem(tokenPathAdmin, token)

export const removeStoredAuthTokenAdmin = () => windowGlobal?.localStorage?.removeItem(tokenPathAdmin)
